import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpParams } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { AppConfig } from '../../../app.config';
import { Observable } from 'rxjs';
import { OrganizationalUnit } from '../models/organizational-unit.model';
import { ICurrentUser } from '../models/ICurrentUser.model';
import { OrganizationalUnitConfig } from '../models/organizational-unit-config.model';
import { IPwdReset } from '../models/pwd-reset.model';

@Injectable({
  providedIn: 'root'
})
export class AuthService {
  url = AppConfig.settings.apiUrls.auth;
  identityChangedCallbacks = [];

  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type': 'application/x-www-form-urlencoded',
      Application: AppConfig.settings.application.code
    })
  };

  constructor(private http: HttpClient) { }

  login(user, password) {
    const body = new HttpParams()
      .set('client_id', 'COCA')
      .set('client_secret', 'SECRET')
      .set('grant_type', 'password')
      .set('username', user)
      .set('password', password);
    return this.http.post(`${this.url}/token`, body, this.httpOptions).pipe(
      map(res => {
        this.setSession(res);
        return res;
      })
    );
  }

  private setSession(authResult): void {
    localStorage.setItem('access_token', authResult.access_token);
    localStorage.setItem('roles', authResult.roles);
    localStorage.setItem('usersOu', authResult.usersOu);
    localStorage.setItem('organizationId', authResult.organizationId);
    localStorage.setItem('organizationName', authResult.appOrganizationName);
    localStorage.setItem('refresh_token', authResult.refresh_token);
    localStorage.setItem('userId', authResult.userId);
    localStorage.setItem('userNick', authResult.userNick);
    localStorage.setItem('userFirstname', authResult.userName);
    localStorage.setItem('userLastname', authResult.userLastname);
    localStorage.setItem('urlIcon', authResult.urlIcon);

    this.identityChanged();
  }

  logout() {
    localStorage.clear();
    this.identityChanged();
  }

  refreshToken(): Observable<ICurrentUser> {
    // Reemplazo el access_token por el nuevo
    const body = new HttpParams()
      .set('client_id', 'COCA')
      .set('client_secret', 'SECRET')
      .set('grant_type', 'refresh_token')
      .set('refresh_token', localStorage.getItem('refresh_token'));
    return this.http
      .post<ICurrentUser>(`${this.url}/token`, body, this.httpOptions)
      .pipe(
        map(user => {
          if (user && user.access_token) {
            this.setSession(user);
          }

          return <ICurrentUser>user;
        })
      );
  }

  getUserRoles(): string {
    return localStorage.getItem('roles');
  }

  getOrganizationId(): string {
    return localStorage.getItem('organizationId');
  }

  getOrganizationName(): string {
    return localStorage.getItem('organizationName');
  }

  getOrganizationalUnits(): OrganizationalUnit[] {
    const userOus = JSON.parse(localStorage.getItem('usersOu'));
    const organizationalUnits = [];

    for (let index = 0; index < userOus.length; index++) {
      const ou = userOus[index].organizationalUnit;

      if (ou) {
        organizationalUnits.push({
          id: ou.id,
          name: ou.name,
          isRoot: ou.isRoot,
          roles: userOus[index].roles
        });
      }
    }

    return organizationalUnits;
  }

  isInRole(rol: string): boolean {
    if (!localStorage.getItem('roles')) {
      return false;
    }

    const roles = JSON.parse(localStorage.getItem('roles'));

    return roles.indexOf(rol) >= 0;
  }

  getUserId(): string {
    return localStorage.getItem('userId');
  }

  getUserNick(): string {
    return localStorage.getItem('userNick');
  }

  isAuthenticated(): Boolean {
    // If the user have a access_Token we can assume that is Authenticated
    let haveToken = false;
    if (this.getAccesToken()) {
      haveToken = true;
    }

    return haveToken;
  }

  getAccesToken(): any {
    return localStorage.getItem('access_token');
  }

   forgotPassword(
     username: string,
     changePasswordCallbackUrl: string,
     appid: string
   ): Observable<any> {
     let params = new HttpParams();

     params = params.append('userName', username);
     params = params.append('callbackUrl', changePasswordCallbackUrl);
     params = params.append('appId', appid);

     return this.http
       .get<any>(`${this.url}/Account/ForgotPassword`, { params: params })
       .pipe(map(res => res));
   }

   resetPassword(pwd: IPwdReset): Observable<any> {
     const httpOpt = {
       headers: new HttpHeaders({
         'Content-Type': 'application/json'
       })
     };

     const postData = JSON.stringify(pwd);
     return this.http
       .post<any>(`${this.url}/Account/ResetPassword`, postData, httpOpt)
       .pipe(map(res => res));
  }

  onIdentityChanged(callback) {
    this.identityChangedCallbacks.push(callback);
  }

  async setUserOu(ou: string): Promise<any> {
    // si ya esta no lo cambio
    if (this.getOrganizationId() === ou) {
      return;
    }

    return await this.changeUserOu(ou)
      .toPromise()
      .then(async () => {
        return await this.refreshToken().toPromise();
      });
  }

  async getTokenChangeApp(): Promise<string> {
    return await this.changeApp()
      .toPromise()
      .then(async (result) => {
        return result.token;
      });
  }

  getUserOus(): Observable<OrganizationalUnitConfig[]> {
    return this.http
      .get<any>(
        `${this.url}/Logins/GetOuByApp/${AppConfig.settings.application.id}`
      )
      .pipe(
        map(res => {
          const ous: OrganizationalUnitConfig[] = [];
          for (const entity of res.value) {
            ous.push(
              new OrganizationalUnitConfig(
                entity.entityId,
                entity.entityDescription
              )
            );
          }
          return ous;
        })
      );
  }

  private identityChanged() {
    this.identityChangedCallbacks.forEach(callback => {
      callback();
    });
  }

  private changeUserOu = function (ou: string): Observable<any> {
    return this.http.put(`${this.url}/logins/SetOu/${ou}`);
  };

  private changeApp = function (): Observable<any> {
    return this.http.post(`${this.url}/AppSwitch`);
  };
}
