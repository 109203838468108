import { Routes, RouterModule } from '@angular/router';

const routes: Routes = [
    { path: 'login', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
    { path: 'accessDenied', loadChildren: () => import('./modules/access-denied/access-denied.module').then(m => m.AccessDeniedModule) },
    { path: 'login/:t', loadChildren: () => import('./modules/login/login.module').then(m => m.LoginModule) },
    { path: 'home', loadChildren: () => import('./modules/home/home.module').then(m => m.HomeModule) },
    { path: 'ou-admin/:id', loadChildren: () => import('./modules/organizational-unit-admin/organizational-unit-admin.module').then(m => m.OrganizationalUnitAdminModule) },
    { path: 'pwd-reset/:code', loadChildren: () => import('./modules/pwd-reset/pwd-reset.module').then(m => m.PwdResetModule) },
    { path: '**', pathMatch: 'full', redirectTo: 'login' }
    
];

export const APP_ROUTING = RouterModule.forRoot(routes, { useHash: true, relativeLinkResolution: 'corrected' });
