import { Pipe, PipeTransform } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Pipe({name: 'msjDescriptionPipe'})

export class MsjDescriptionPipe implements PipeTransform {
    private data: any;

    constructor( private http: HttpClient) {
        this.load();
    }

    async load () {
        if (!this.data) {
            const result = await this.http.get('assets/error.json').toPromise();
            this.data = result;
        }
    }

    transform(value: any): string {
        const response = 'Ocurrio un error, intente nuevamente.';
        let responseDescription;

        if (value) {
            responseDescription = value.description;
        }

        try {
            if (!this.data) {
                return response;
            }

            if (value.code || value.description) {
                // tslint:disable-next-line:no-eval
                return eval(`this.data.${value.code}`)  || eval(`this.data.${value.description}`) || response;
            }

            // tslint:disable-next-line:no-eval
            return eval(`this.data.${value}`) || response;
        } catch (Exception) {
            return responseDescription || response;
        }
    }
}
