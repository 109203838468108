import { Injectable } from '@angular/core';
import { HttpClient, HttpBackend } from '@angular/common/http';
import { environment } from '../environments/environment';
import { IAppConfig } from './modules/shared/models/app-config.model';

@Injectable()
export class AppConfig {

    static settings: IAppConfig;
    private httpClient: HttpClient;

    constructor(private handler: HttpBackend) {
        this.httpClient = new HttpClient(handler);
      }

      load() {
        const jsonFile = `assets/config/config.${environment.name}.json`;
        
        return new Promise<void>((resolve, reject) => {
          this.httpClient.get<any>(jsonFile).toPromise()
          .then(response => {
               AppConfig.settings = <IAppConfig>response;
               resolve();
            }).catch((response: any) => {
               reject(`Could not load file '${jsonFile}': ${JSON.stringify(response)}`);
            });
        });
    }
}
