import { BrowserModule } from '@angular/platform-browser';
import { LOCALE_ID, NgModule } from '@angular/core';
import { APP_ROUTING } from './app.routes';
import { AppComponent } from './app.component';
import { MatRippleModule } from '@angular/material/core';
import { MAT_SNACK_BAR_DEFAULT_OPTIONS } from '@angular/material/snack-bar';
import { HttpClientModule, HTTP_INTERCEPTORS } from '@angular/common/http';
import { APP_INITIALIZER } from '@angular/core';
import { AppConfig } from './app.config';
import localeEs from '@angular/common/locales/es';
import { registerLocaleData } from '@angular/common';
registerLocaleData(localeEs);
import { FlexLayoutModule } from '@angular/flex-layout';

// Material animation -- Optional
import { MyMaterialModule } from './app.material';
import { FormsModule } from '@angular/forms';
import { TokenInterceptor } from './modules/shared/auth/token-interceptor';
import { MsjDescriptionPipe } from './modules/shared/pipes/msj-description.pipe';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { DialogModule } from './modules/shared/errorHandler/dialog.module';
import { DialogComponent } from './modules/shared/errorHandler/dialog.component';
import { SecretUserModule } from './modules/secret-user/secret-user.module';

export function initializeApp(appConfig: AppConfig) {
  return () => appConfig.load();
}

@NgModule({
    declarations: [
        AppComponent
    ],
    imports: [
        BrowserAnimationsModule,
        BrowserModule,
        HttpClientModule,
        APP_ROUTING,
        FormsModule,
        MyMaterialModule,
        FlexLayoutModule,
        DialogModule,
        MatRippleModule,
        SecretUserModule
    ],
    providers: [
        AppConfig, { provide: APP_INITIALIZER, useFactory: initializeApp, deps: [AppConfig], multi: true },
        { provide: MAT_SNACK_BAR_DEFAULT_OPTIONS, useValue: { duration: 5000 } },
        { provide: LOCALE_ID, useValue: 'es' },
        { provide: HTTP_INTERCEPTORS, useClass: TokenInterceptor, multi: true },
        MsjDescriptionPipe
    ],
    bootstrap: [AppComponent]
})
export class AppModule { }

